import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

class Pics extends React.Component {
  render() {
    const siteTitle = 'Alexander Katin';

    return (
      <SEO
        title="Home"
        keywords={['blog', 'gatsby', 'javascript', 'react']}
      />
    );
  }
}

export default Pics;
